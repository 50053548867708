import React, {ChangeEvent, useState} from "react";
import { FormControl, Icon, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import './search-filter.scss';

interface SearchFilterProps {
    onSearchChanged: (searchText: string) => void;
}

export const SearchFilter = (props: SearchFilterProps) => {
    const [searchText, setSearchText] = useState<string>('');

    function handleSearchTextChanged(event: ChangeEvent<HTMLTextAreaElement>) {
        const newText = event.target.value;
        setSearchText(newText);
        props.onSearchChanged(newText);
    }

    return (
        <>
            <FormControl fullWidth>
                <InputLabel className="search-filter__label" htmlFor="searchField">Search</InputLabel>
                <Input
                    id="searchField"
                    value={searchText}
                    onChange={handleSearchTextChanged}
                    autoFocus={true}
                    endAdornment={<InputAdornment position="end">
                        <IconButton aria-label="Search">
                            <Icon>search</Icon>
                        </IconButton>
                    </InputAdornment>}
                />
            </FormControl>
        </>
    );
};
