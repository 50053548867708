import { useState } from 'react';
import constate from "constate";

export enum View {
    MAIN,
    NEW_RELEASES
}

export const [ViewStoreProvider, useViewStore] = constate(() => {
    const [view, setView] = useState<View>(View.MAIN);
    return { view, setView };
});
