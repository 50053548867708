import React, {useEffect, useState} from 'react';
import { Header } from '../component/header/header';
import './new-release-page.scss';
import {useViewStore, View} from "../stores/view-store";
import Button from "@material-ui/core/Button";
import {NewReleaseList} from "../component/new-release-list/new-release-list";
import {getNewReleases} from "../api/api";
import {Item} from "../api/data";

export const NewReleasePage = () => {
    const [items, setItems] = useState<Item[]>([]);
    const { setView } = useViewStore();

    function handleOnClickNavigateToMain(e: any) {
        e.preventDefault();
        setView(View.MAIN);
    }

    useEffect(() => {
        getNewReleases(0, 25)
            .then(res => setItems(res.data || []));
    }, []);

    return (
        <>
            <Header />
            <main className="new-release-page">
                <div className="new-release-page__content">
                    <Button className="new-release-page__navigate-btn" onClick={handleOnClickNavigateToMain}>Home</Button>
                    <NewReleaseList items={items} />
                </div>
            </main>
        </>
    );
};

