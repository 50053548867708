export enum ItemType {
	MOVIE = 'MOVIE',
	MANGA = 'MANGA',
	SHOW = 'SHOW'
}

export interface Item {
	_id: string;
	name: string;
	alias1: string;
	url: string;
	newestRelease: number | undefined;
	type: ItemType;
	progress: number;
	isCompleted: boolean;
	lastUpdated: string;
	createdAt: string;
}

export interface CreateItemData {
	name: string;
	alias1: string;
	url: string;
	progress: number;
	type: ItemType;
}

export interface UpdateItemData {
	name: string;
	alias1: string;
	url: string;
	progress: number;
	isCompleted: boolean;
}
