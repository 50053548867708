import React from "react";
import {Item, ItemType} from "../../api/data";
import {Show} from "../show";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import './new-release-list.scss';
import {toTimestampText} from "../../utils/date-utils";

interface NewReleaseListProps {
    items: Item[];
}

function mapItemToView(item: Item, onItemClicked: (item: Item) => void) {
    let progressStr;
    let newestReleaseStr;

    if (item.type === ItemType.MANGA) {
        progressStr = "Ch: " + item.progress;
        newestReleaseStr = "Ch: " + item.newestRelease;
    } else if (item.type === ItemType.SHOW) {
        progressStr = "Ep: " + item.progress;
        newestReleaseStr = "Ep: " + item.newestRelease;
    }

    return (
        <TableRow className="new-release-list__item" key={item._id} onClick={() => onItemClicked(item)}>
            <TableCell component="th" scope="row">
                {item.name}
            </TableCell>
            <TableCell>{progressStr}</TableCell>
            <TableCell>{newestReleaseStr}</TableCell>
            <TableCell>{toTimestampText(item.lastUpdated)}</TableCell>
        </TableRow>
    );
}

export function NewReleaseList(props: NewReleaseListProps) {
    const items = props.items;
    const hasItems = items.length > 0;

    function onItemClicked(item: Item) {
        window.open(item.url,'_blank');
    }

    return (
        <>
            <Show if={!hasItems}>
                <h1 className="new-release-list__header">No new releases</h1>
            </Show>
            <Show if={hasItems}>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Latest release</TableCell>
                                <TableCell>Last Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasItems ? items.map(item => mapItemToView(item, onItemClicked)) : null}
                        </TableBody>
                    </Table>
                </Paper>
            </Show>
        </>
    );
}