import { Item, ItemType } from '../api/data';

export function mapItemTypeToText(itemType: ItemType): string {
	return itemType.toLowerCase();
}

export function hasItemChanged(itemBefore: Item, maybeChangedItem: Item): boolean {
	return (itemBefore.name || '') !== (maybeChangedItem.name || '')
		|| (itemBefore.alias1 || '') !== (maybeChangedItem.alias1 || '')
		|| (itemBefore.url || '')  !== (maybeChangedItem.url || '')
		|| itemBefore.progress !== maybeChangedItem.progress
		|| itemBefore.isCompleted !== maybeChangedItem.isCompleted;
}

export function itemTypeProgressText(itemType: ItemType): string {
	return itemType === ItemType.MANGA
		? 'Chapters Read'
		: 'Episodes Watched';
}

interface ValueEvent {
	target: { value: string }
}

export function setEventValue(setValue: (val: string) => void): (event: ValueEvent) => void {
	return (event: ValueEvent) => setValue(event.target.value);
}

interface CheckedEvent {
	target: { checked: boolean }
}

export function setEventChecked(setChecked: (val: boolean) => void): (event: CheckedEvent) => void {
	return (event: CheckedEvent) => setChecked(event.target.checked);
}
