import { CreateItemData, Item, UpdateItemData } from './data';
import { fetchJson, FetchResponse, fetchWithChecks } from './fetch-utils';
import { env } from '../utils/environment';

const jsonHeaders = {
	'Content-Type': 'application/json'
};

export function getItems(search: string, skip: number, limit: number): Promise<FetchResponse<Item[]>> {
	return fetchJson<Item[]>(`${env.apiUrl}/api/items?skip=${skip}&limit=${limit}&search=${search}`);
}

export function getNewReleases(skip: number, limit: number): Promise<FetchResponse<Item[]>> {
	return fetchJson<Item[]>(`${env.apiUrl}/api/items/newReleases?skip=${skip}&limit=${limit}`);
}

export function isAuthenticated(): Promise<boolean> {
	return fetchJson<boolean>(`${env.apiUrl}/api/auth/authenticated`)
		.then(res => !!res.data);
}

export function createItem(createItemData: CreateItemData): Promise<Response> {
	return fetchWithChecks(`${env.apiUrl}/api/items`, {
		credentials: 'include',
		method: 'POST',
		body: JSON.stringify(createItemData),
		headers: jsonHeaders
	});
}

export function updateItem(itemId: string, updateItemData: UpdateItemData): Promise<Response> {
	return fetchWithChecks(`${env.apiUrl}/api/items/${itemId}`, {
		credentials: 'include',
		method: 'PUT',
		body: JSON.stringify(updateItemData),
		headers: jsonHeaders
	});
}

export function deleteItem(itemId: string): Promise<Response> {
	return fetchWithChecks(`${env.apiUrl}/api/items/${itemId}`, {
		credentials: 'include',
		method: 'DELETE'
	});
}
