import React from 'react';
import { StylesProvider } from '@material-ui/core/styles'
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {themeColor} from "./theme-color";
import {AuthenticationCheck} from "./component/authentication-check/authentication-check";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {StoreProvider} from "./stores/store-provider";
import {ViewController} from "./view-controller";

const theme = createMuiTheme({
    palette: {
        primary: themeColor,
    }
});

/*
    <StylesProvider> loads our own CSS rules after the Material UI rules, so that we can override the styling
*/

export const App = () => {
  return (
      <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
              <AuthenticationCheck>
                  <StoreProvider>
                      <ViewController/>
                  </StoreProvider>
                  <ToastContainer />
              </AuthenticationCheck>
          </MuiThemeProvider>
      </StylesProvider>
  );
};
