import './create-item-modal.scss';
import React, { useEffect, useState } from 'react';
import { CreateItemData, ItemType } from '../../api/data';
import {
	Button,
	Checkbox,
	Dialog, DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel, FormLabel, IconButton,
	Input,
	InputLabel, Radio, RadioGroup
} from '@material-ui/core';
import {SlideDown} from "../../component/transitions";
import './create-item-modal.scss';
import { Show } from '../../component/show';
import CloseIcon from '@material-ui/icons/Close';
import { itemTypeProgressText, setEventChecked, setEventValue } from '../../utils';
import { Typography } from '@material-ui/core';
import {showErrorToast} from "../../utils/toast-uttils";

interface CreateItemModalProps {
	show: boolean;
	onHide: () => void;
	onCreateItem: (item: CreateItemData) => void;
}

export const CreateItemModal = (props: CreateItemModalProps) => {
	const [name, setName] = useState<string>('');
	const [alias1, setAlias1] = useState<string>('');
	const [url, setUrl] = useState<string>('');
	const [progress, setProgress] = useState<string>('');
	const [isCompleted, setIsCompleted] = useState<boolean>(false);
	const [itemType, setItemType] = useState<ItemType>(ItemType.MANGA);

	function handleCreateItem() {
		if (!name || name.trim().length === 0) {
			showErrorToast('Name is missing');
			return;
		}

		const progressNumber = progress.trim() === '' ? 0 : parseFloat(progress);

		if (isNaN(progressNumber)) {
			showErrorToast('Progress is not a number');
			return;
		}

		const createItemData: CreateItemData = {
			alias1: alias1,
			name: name,
			progress: progressNumber,
			type: itemType,
			url: url
		};

		props.onCreateItem(createItemData);
	}

	useEffect(() => {
		setName('');
		setAlias1('');
		setUrl('');
		setProgress('');
		setIsCompleted(false);
		setItemType(ItemType.MANGA);
	}, [props.show]);

	return (
		<Dialog
			open={props.show}
			TransitionComponent={SlideDown}
			keepMounted
			fullWidth
			maxWidth="sm"
		>
			<div className="create-item-modal__title">
				<Typography variant="h6">Create</Typography>
				<IconButton className="create-item-modal__close-btn" aria-label="close" onClick={props.onHide}><CloseIcon /></IconButton>
			</div>

			<DialogContent>
				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="nameField">Name</InputLabel>
					<Input id="nameField" value={name} onChange={setEventValue(setName)} />
				</FormControl>

				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="aliasField">Alias</InputLabel>
					<Input id="aliasField" value={alias1} onChange={setEventValue(setAlias1)} />
				</FormControl>

				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="urlField">Url</InputLabel>
					<Input id="urlField" value={url} onChange={setEventValue(setUrl)} />
				</FormControl>

				<Show if={[ItemType.MANGA, ItemType.SHOW].includes(itemType)}>
					<FormControl className="form-field" fullWidth>
						<InputLabel htmlFor="progressField">{itemTypeProgressText(itemType)}</InputLabel>
						<Input type="number" id="progressField" value={progress} onChange={setEventValue(setProgress)}/>
					</FormControl>
				</Show>

				<FormControl className="form-field" component="fieldset" fullWidth>
					<FormLabel component="legend">Content Type</FormLabel>
					<RadioGroup
						aria-label="Type"
						name="itemType"
						value={itemType}
						onChange={e => setItemType(e.target.value as ItemType)}
						className="create-item-modal__radio-group"
					>
						<FormControlLabel className="create-item-modal__radio--margin" value={ItemType.MANGA} label="Manga" control={<Radio />} />
						<FormControlLabel className="create-item-modal__radio--margin" value={ItemType.SHOW} label="Show" control={<Radio />} />
						<FormControlLabel value={ItemType.MOVIE} label="Movie" control={<Radio />} />
					</RadioGroup>
				</FormControl>

				<FormControl className="form-field" component="fieldset" fullWidth>
					<FormControlLabel
						control={
							<Checkbox
								checked={isCompleted}
								onChange={setEventChecked(setIsCompleted)}
								color="primary"
							/>
						}
						label="Completed"
					/>
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleCreateItem} color="primary">Save</Button>
			</DialogActions>
		</Dialog>
	);
};
