
class Environment {

	get apiUrl(): string {
		return process.env.REACT_APP_API_URL as string;
	}

}

export const env = new Environment();
