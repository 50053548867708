import * as React from 'react';
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import './header.scss';

export const Header = () => {
	return (
		<AppBar position="static" className="header">
			<Toolbar>
				<Typography variant="h1" color="inherit" className="header__title">
					Library Manager
				</Typography>
			</Toolbar>
		</AppBar>
	);
};
