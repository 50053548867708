import React from "react";
import {useViewStore, View} from './stores/view-store';
import {NewReleasePage} from "./page/new-release-page";
import {MainPage} from "./page/main-page";

export function ViewController() {
    const { view } = useViewStore();

    switch (view) {
        case View.NEW_RELEASES:
            return <NewReleasePage/>;
        default:
            return <MainPage/>;
    }
}