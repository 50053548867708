import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import './main-page.scss';
import { Header } from '../component/header/header';
import { ItemList } from '../component/item-list/item-list';
import { CreateItemData, Item } from '../api/data';
import { createItem, deleteItem, getItems, updateItem } from '../api/api';
import { EditItemModal } from '../modal/edit-item-modal/edit-item-modal';
import { CreateItemModal } from '../modal/create-item-modal/create-item-modal';
import { hasItemChanged } from '../utils';
import {useViewStore, View} from '../stores/view-store';
import {SearchFilter} from "../component/search-filter/search-filter";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { CircularProgress } from '@material-ui/core';
import {showErrorToast, showSuccessToast} from "../utils/toast-uttils";

export const MainPage = () => {
	const { setView } = useViewStore();
	const [showCreateItemModal, setShowCreateItemModal] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<Item | null>(null);
	const [searchText, setSearchText] = useState<string>('');
	const [items, setItems] = useState<Item[]>([]);
	const [isFetchingItems, setIsFetchingItems] = useState(true);

	const debouncedFetchItems = useCallback(debounce((searchText: string) => fetchItems(searchText), 300), []);

	function fetchItems(searchFor: string) {
		setIsFetchingItems(true);
		getItems(searchFor, 0, 25)
			.then(itemsResponse => {
				if (itemsResponse.data) {
					setItems(itemsResponse.data);
				} else if (itemsResponse.error) {
					showErrorToast('Failed to retrieve items\n' + itemsResponse.httpCode + ': ' + itemsResponse.error);
				}
			})
			.finally(() => {
				setIsFetchingItems(false);
			});
	}

	function handleCreateItemClicked() {
		setShowCreateItemModal(true);
	}

	function handleItemClicked(item: Item) {
		setSelectedItem(item);
	}

	function handleCreateItem(createItemData: CreateItemData) {
		setShowCreateItemModal(false);
		createItem(createItemData)
			.then(() => {
				// Remove search so that the newly created item shows on top
				if (searchText === '') {
					fetchItems('');
				} else {
					setSearchText('');
				}
			})
			.catch(() => {
				showErrorToast('Failed to create item ' + createItemData.name);
			});
	}

	function handleOnItemModalHide(changedItem: Item) {
		setSelectedItem(null);

		const oldItem = items.find(i => i._id === changedItem._id);

		// Optimization. We dont need to update if the item has not changed
		if (oldItem && !hasItemChanged(oldItem, changedItem)) {
			return;
		}

		updateItem(changedItem._id, {
			name: changedItem.name,
			alias1: changedItem.alias1,
			url: changedItem.url,
			progress: changedItem.progress,
			isCompleted: changedItem.isCompleted
		}).then(() => {
			setItems(curItems => {
				const itemsWithoutChanged = curItems.filter(i => i._id !== changedItem._id);
				return [changedItem, ...itemsWithoutChanged];
			});
			showSuccessToast('Item updated');
		}).catch(() => {
			showErrorToast('Failed to update: ' + changedItem.name);
		});
	}

	function handleOnItemDeleted(itemId: string) {
		setSelectedItem(null);
		deleteItem(itemId)
			.then(() => {
				setItems(curItems => curItems.filter(i => i._id !== itemId))
			})
			.catch(() => {
				showErrorToast('Failed to delete: ' + itemId)
			});
	}

	function handleOnClickNavigateToNewReleases(e: any) {
		e.preventDefault();
		setView(View.NEW_RELEASES);
	}

	useEffect(() => {
		debouncedFetchItems(searchText);
		// eslint-disable-next-line
	}, [searchText]);

	return (
		<>
			<Header />
			<main className="main-page">
				<div className="main-page__content">
					<Button className="main-page__navigate-btn" onClick={handleOnClickNavigateToNewReleases}>New Releases</Button>
					<div className="main-page__search_create">
						<SearchFilter onSearchChanged={setSearchText}/>
						<Button className="create-btn" variant="contained" color="primary" size="small" onClick={handleCreateItemClicked}>
							Create new <Icon className="create-btn__create-icon">add</Icon>
						</Button>
					</div>
					<div className="main-page__items">
						{
							isFetchingItems
								? (
									<div className="main-page__spinner"><CircularProgress /></div>
								)
								: <ItemList items={items} onItemClicked={handleItemClicked}/>
						}
					</div>
				</div>
			</main>
			<CreateItemModal show={showCreateItemModal} onHide={() => setShowCreateItemModal(false)} onCreateItem={handleCreateItem}/>
			<EditItemModal showItem={selectedItem} onHide={handleOnItemModalHide} onDeleteItem={handleOnItemDeleted}/>
		</>
	);
};

