import {Item, ItemType} from '../../api/data';
import React from 'react';
import './item-list.scss';
import { toTimestampText } from '../../utils/date-utils';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Show } from '../show';

interface ItemListProps {
    items: Item[];
    onItemClicked: (item: Item) => void;
}

function mapItemToView(item: Item, onItemClicked: (item: Item) => void) {
    let contentTypeStr;
    let progressStr;

    if (item.type === ItemType.MANGA) {
        contentTypeStr = "Manga";
        progressStr = "Ch: " + item.progress;
    } else if (item.type === ItemType.SHOW) {
        contentTypeStr = "Show";
        progressStr = "Ep: " + item.progress;
    } else {
        contentTypeStr = "Movie";
        progressStr = item.isCompleted ? 'Finished' : 'Not finished';
    }

    return (
        <TableRow className="item-list__item" key={item._id} onClick={() => onItemClicked(item)}>
            <TableCell component="th" scope="row">
                {item.name} {/* TODO: Or alias */}
            </TableCell>
            <TableCell>{progressStr}</TableCell>
            <TableCell>{contentTypeStr}</TableCell>
            <TableCell>{toTimestampText(item.lastUpdated)}</TableCell>
        </TableRow>
    );
}

export const ItemList = (props: ItemListProps) => {
    const { items, onItemClicked } = props;
    const hasItems = items.length > 0;

    return (
        <div className="item-list">
            <Show if={!hasItems}>
                <h1 className="item-list__header">No contents matched your search</h1>
            </Show>
            <Show if={hasItems}>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Last Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasItems ? items.map(item => mapItemToView(item, onItemClicked)) : null}
                        </TableBody>
                    </Table>
                </Paper>
            </Show>
        </div>
    );
};
