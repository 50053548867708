import React, { PropsWithChildren } from 'react';
import { ViewStoreProvider } from './view-store';

export const StoreProvider = (props: PropsWithChildren<{}>) => {
    return (
        <ViewStoreProvider>
            {props.children}
        </ViewStoreProvider>
    );
};
