import './edit-item-modal.scss';
import React, { useEffect, useState } from 'react';
import { Item, ItemType } from '../../api/data';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Input,
	InputLabel
} from '@material-ui/core';
import { SlideDown } from '../../component/transitions';
import { mapItemTypeToText, itemTypeProgressText, setEventValue, setEventChecked } from '../../utils';
import { Show } from '../../component/show';
import './edit-item-modal.scss';
import {showErrorToast} from "../../utils/toast-uttils";

interface EditItemModalProps {
	showItem: Item | null; // Modal will be shown if item is not null
	onHide: (changedItem: Item) => void;
	onDeleteItem: (itemId: string) => void;
}

export const EditItemModal = (props: EditItemModalProps) => {
	const [name, setName] = useState<string>('');
	const [alias1, setAlias1] = useState<string>('');
	const [url, setUrl] = useState<string>('');
	const [progress, setProgress] = useState<string>('');
	const [isCompleted, setIsCompleted] = useState<boolean>(false);

	const { showItem, onHide, onDeleteItem } = props;

	function handleOnHide() {
		const progressNumber = progress.trim() === '' ? 0 : parseFloat(progress);

		if (isNaN(progressNumber)) {
			showErrorToast('Progress is not a number');
			return;
		}

		const changedItem = Object.assign({}, showItem, {
			name,
			alias1,
			url,
			progress: progressNumber,
			isCompleted,
			lastUpdated: new Date().toISOString()
		});

		onHide(changedItem);
	}

	function handleOnDeleteItem() {
		const item = showItem as Item;
		const isDeleteConfirmed = window.confirm(`Confirm deletion of "${item.name}"`);
		if (isDeleteConfirmed) {
			onDeleteItem(item._id);
		}
	}

	useEffect(() => {
		if (showItem) {
			setName(showItem.name);
			setAlias1(showItem.alias1 || '');
			setUrl(showItem.url || '');
			setProgress(showItem.progress.toString());
			setIsCompleted(showItem.isCompleted);
		}
	}, [showItem]);


	if (!showItem) {
		return null;
	}

	return (
		<Dialog
			open={true}
			onClose={handleOnHide}
			TransitionComponent={SlideDown}
			keepMounted
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>
				Edit {mapItemTypeToText(showItem.type)}
			</DialogTitle>

			<DialogContent>
				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="nameField">Name</InputLabel>
					<Input id="nameField" value={name} onChange={setEventValue(setName)} disabled={isCompleted} />
				</FormControl>

				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="aliasField">Alias</InputLabel>
					<Input id="aliasField" value={alias1} onChange={setEventValue(setAlias1)} disabled={isCompleted} />
				</FormControl>

				<FormControl className="form-field" fullWidth>
					<InputLabel htmlFor="urlField">Url</InputLabel>
					<Input id="urlField" value={url} onChange={setEventValue(setUrl)} disabled={isCompleted} />
				</FormControl>

				<Show if={[ItemType.MANGA, ItemType.SHOW].includes(showItem.type)}>
					<FormControl className="form-field" fullWidth>
						<InputLabel htmlFor="progressField">{itemTypeProgressText(showItem.type)}</InputLabel>
						<Input type="number" id="progressField" value={progress} autoFocus={true} disabled={isCompleted} onChange={setEventValue(setProgress)}/>
					</FormControl>
				</Show>

				<div className="edit-item-modal__footer">
					<FormControlLabel
						control={
							<Checkbox
								checked={isCompleted}
								onChange={setEventChecked(setIsCompleted)}
								color="primary"
							/>
						}
						label="Completed"
					/>

					<Button onClick={handleOnDeleteItem} className="edit-item-modal__delete-btn">
						Delete
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
