
export interface FetchResponse<D = any> {
	error?: any;
	data?: D;
	httpCode?: number;
}

export function fetchWithChecks<D>(input: RequestInfo, init?: RequestInit): Promise<Response> {
	return fetch(input, init)
		.then(res => {
			if (res.status >= 400) {
				throw new Error('Kall feilet med status ' + res.status);
			}

			return res;
		});
}

export function fetchJson<D>(input: RequestInfo, init?: RequestInit): Promise<FetchResponse<D>> {
	const options = init ? init : {};
	options.credentials = 'include';

	if (!options.headers) {
		options.headers = {
			'Accept': 'application/json',
			'Content-Type':  'application/json'
		};
	}

	return fetch(input, options)
		.then(async (res) => {
			const status = res.status;
			try {
				if (status >= 300) {
					const error = await res.text();
					console.error('API kall feilet med status ', status);
					return { error, httpCode: status };
				}
				const json = await res.json();
				return { data: json, httpCode: status };
			} catch (err) {
				return { error: err, httpCode: status };
			}
		})
		.catch(error => {
			return { error };
		});
}
