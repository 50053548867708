import React, {useEffect, useState} from "react";
import {isAuthenticated} from "../../api/api";
import { env } from '../../utils/environment';
import {Header} from "../header/header";
import {CircularProgress} from "@material-ui/core";
import './authentication-check.scss';

interface AuthenticationCheckProps {
    children: any;
}

export function AuthenticationCheck(props: AuthenticationCheckProps) {
    const [finishedAuthenticating, setFinishedAuthenticating] = useState(false);
    const [authenticationFailed, setAuthenticationFailed] = useState(false);

    useEffect(() => {
        isAuthenticated()
            .then(isAuthenticated => {
                if (!isAuthenticated) {
                    window.location.href = env.apiUrl + `/api/auth/login?returnUrl=${window.location.href}`;
                } else {
                    setFinishedAuthenticating(true);
                }
            })
            .catch(() => setAuthenticationFailed(true));
    }, []);

    if (authenticationFailed) {
        return (
            <>
                <Header/>
                <div className="authentication-check__content">
                    <h1>Authentication failed :(</h1>
                </div>
            </>
        );
    } else if (!finishedAuthenticating) {
        return (
            <>
                <Header/>
                <div className="authentication-check__content">
                    <CircularProgress size="64px" />
                </div>
            </>
        );
    }

    return props.children;
}